.testimonies{
    background-color: black;
    box-sizing: border-box;
    padding: 5rem;
    color: white;
}

.testimonies h1{
    text-align: center;
    margin-bottom: 5rem;
}

.testimonial{
    border-bottom: 3px red solid;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 250%;
    margin: auto;
    display: flex;
    animation-name: fade;
    animation-duration: 16s;
    animation-iteration-count: infinite;
}

@media (max-width: 1000px) {
    .testimonies h1{
        margin-bottom: 5rem;
    }

    .testimonial{
        font-size: 175%;
    }
}

@media (max-width: 500px) {
    .testimonies{
        min-height: 40rem;
    }

    .testimonies h1{
        margin-bottom: 5rem;
    }
    .testimonial{
        font-size: 100%;
    }
}

@keyframes fade{
    0% {opacity: 0;}
    25% {opacity: 1;}
    60% {opacity: 1;}
    100% {opacity: 0;}
}