.card{
    max-width: 20rem;
    flex: 1 1 20rem;
    box-sizing: border-box;
    text-align: center;
    margin: 25px auto;
    border-top: 3px red solid;
    box-shadow: -1px 3px 3px gray;
}

.cardImg{
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
}

.card img{
    width: 100%;
    height: 10rem;
    object-fit: cover;
    object-position: 50% 25%;
}

.cardInfo p{
    padding: 2rem 1rem;
    box-sizing: border-box;
}

@media (max-width: 350px) {
    .cardInfo{
        font-size: 75%;
    }
}