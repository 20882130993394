.about{
    min-height: 100vh;
    background-image: url('../../Assets/Images/Background/about.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 25%;
    color: white;
    padding: 3rem;
}

.title{
    text-align: center;
    margin-bottom: 3rem;
}

.section{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
}