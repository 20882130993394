.services{
    padding: 2rem;
}

.title{
    text-align: center;
    margin-top: 2rem;
}

.section{
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
}