.footer {
  background-color: black;
  padding: 3rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
}

.logo img {
  width: 20rem;
  object-fit: cover;
}

.pages {
  display: flex;
  column-gap: 5rem;
}

.info {
  flex: 1 1 0;
}

.address {
  margin-top: 10px;
}

.section h3 {
  border-bottom: 2px red solid;
}

.section ul {
  list-style: none;
  margin-top: 10px;
  text-align: left;
  padding: 0;
}

.section li {
  margin-bottom: 10px;
}

.section a {
  text-decoration: none;
  color: white;
}

.section a:hover {
  color: red;
}

@media screen and (max-width: 420px) {
  .logo img {
    width: 15rem;
  }
}

@media screen and (max-width: 300px) {
  .logo img {
    width: 10rem;
  }

  .footer {
    padding: 1rem;
  }
}
