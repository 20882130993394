.navigation{
    position: relative;
    background-color: black;
    padding: 1rem;
    color: white;
    display: flex;
    top: 0;
    z-index: 1000;
}

.logo{
    margin: auto 1rem;
    width: 15rem;
    flex: 1 1 0;
    z-index: -100000;
}

.logo img{
    width: 17rem;
}

.links{
    margin: auto 1rem;
}

.links ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.links li{
    float: left;
    margin: auto 1rem;
    font-size: 125%;
}

.links a{
    text-decoration: none;
    color: white;
}

.links a:hover{
    color: red;
}

.hamburger{
    margin: auto;
    display: none;
}

.hamburger img{
    filter: invert(1);
    width: 2.5rem;
}

.menuActive{
    width: 50%;
    height: 100vh;
    background-color: black;
    position: absolute;
    right: 0;
    z-index: -1;
}

.menuActive ul{
    list-style: none;
    text-align: right;
    margin: 10rem auto;
}

.menuActive li{
    margin-top: 3rem;
}

.menuActive a{
    color: white;
    text-decoration: none;
}

.menuInactive ul{
    display: none;
}

@media (min-width: 700px) {
    .menuActive{
        display: none;
    }

    .menuActive ul{
        display: none;
    }
}

@media (max-width: 700px) {
    .links{
        display: none;
    }

    .hamburger{
        display: inline-block;
    }

    .navigation{
        position: relative;
    }
}

@media (max-width: 400px) {
    .links{
        display: none;
    }

    .hamburger{
        display: inline-block;
    }

    .logo img{
        width: 10rem;
    }
}