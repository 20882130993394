.card{
    flex: 1 30rem;
    box-sizing: border-box;
    padding: 3rem 2rem;
    text-align: center;
    margin: 25px auto;
    border-top: 3px red solid;
    box-shadow: -1px 3px 3px gray;
}

.cardImg{
    width: 5rem;
    height: 5rem;
    margin: auto;
    margin-bottom: 2rem;
}

.card img{
    width: 100%;
}

.cardInfo p{
    padding: 2rem 1rem;
    box-sizing: border-box;
}

@media (max-width: 1640px) {
    .card{
        flex: 1 60rem;
    }
}

@media (max-width: 350px) {
    .cardInfo{
        font-size: 75%;
    }
}