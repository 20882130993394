.landing{
    width: 100vw;
    min-height: 70vh;
    background-image: url('../../../Assets/Images/Background/BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}
    

.information{
    padding: 15rem;
    font-size: 200%;
    max-width: 50rem;
}

.information h1, .information p{
    padding-top: 2rem;
}

@media (min-width: 1000px) {
    .landing{
        height: 100vh;
    }
}

@media (max-width: 1108px) {
    .information{
        padding: 7rem;
        max-width: 60rem;
    }
}

@media (max-width: 750px) {
    .information{
        padding: 5rem;
    }
}

@media (max-width: 550px) {
    .information{
        padding: 1.5rem;
        font-size: 140%;
        margin: auto;
    }
}