.services{
    padding: 5rem;
}

.title{
    text-align: center;
    margin-top: 3rem;
}

.cardRow{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}

@media (max-width: 450px) {
    .services{
        padding: 1rem;
    }
}