.title{
    margin-top: 5rem;
    text-align: center;
}

.information{
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 20px;
}

.mapInfo img{
    border: 1px rgba(128, 128, 128, 0.385) solid;
    border-radius: 10px;
    box-shadow: -2px 2px 2px rgb(129, 129, 129);
}

.section{
    margin-bottom: 3rem;
}

.section h2{
    margin-bottom: 1rem;
}

.section h3{
    margin-bottom: 0.5rem;
}

.section:nth-child(3) p{
    margin-bottom: 0.75rem;
} 

.mapInfo, .contact{
    padding: 1rem 3rem;
    flex: 1 15rem;
    margin-bottom: 20px;
    row-gap: 2rem;
}

.mapInfo{
    text-align: right;
}

@media screen and (max-width: 420px) {
    .mapInfo img{
        width: 15rem;
    }
}

@media screen and (max-width: 300px) {
    .mapInfo img{
        width: 10rem;
    }
}