.card{
    max-width: 30rem;
    flex: 1 30rem;
    box-sizing: border-box;
    padding: 3rem 2rem;
    text-align: center;
    margin: 25px auto;
    background-color: #ED0400;
}

.cardImg{
    width: 5rem;
    height: 5rem;
    margin: auto;
    margin-bottom: 2rem;
}

.card img{
    width: 100%;
}

.cardInfo{
    margin-bottom: 5rem;
}

.cardInfo p{
    padding: 2rem 1rem;
    box-sizing: border-box;
}

@media (max-width: 350px) {
    .cardInfo{
        font-size: 75%;
    }
}